import React, { useState } from 'react'
import {  TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import PageHeader from '../components/sidebar/PageHeader';
import CrearRegistro from '../components/registro/CrearRegistro';
import HistorialRegistro from '../components/registro/HistorialRegistro';
import { RiEdit2Line, RiHistoryLine } from '@remixicon/react';

const Registro = () => {
    const [activeTab, setActiveTab] = useState(0);

  return (
    <div  className='w-full'>
        {/* <PageHeader pageTitle={`Registro ${selectedOption.option ? selectedOption.option : ''} ${selectedResiduo.option ? selectedResiduo.option : ''}`} /> */}
        <PageHeader pageTitle={`Registro`} />
      <main className='mx-10'>
          <TabGroup className='mt-4' onChange={(index) => setActiveTab(index)}>
              <TabList className="flex w-full">
                  <Tab icon={RiEdit2Line}>Captura de registros</Tab>
                  <Tab icon={RiHistoryLine}>Historial de registros</Tab>
              </TabList>
              <TabPanels>
                  <TabPanel>
                      <CrearRegistro/>
                  </TabPanel>
                  <TabPanel>
                      <div className='mt-6'>
                          <HistorialRegistro reset={activeTab === 1}/>
                      </div>
                  </TabPanel>
              </TabPanels>
          </TabGroup>
      </main>
    </div>
  )
}

export default Registro
