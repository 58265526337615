import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const MonthYearPicker = ({ onMonthChange }) => {
  const currentMonthEnd = moment().endOf('month');
  const [selectedDate, setSelectedDate] = useState(moment());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const month = date.month();
    onMonthChange(month);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
        <DatePicker
            views={['month']}
            slotProps={{ textField: { size: 'small'} }}
            label="Seleccionar un mes"
            minDate={moment('2020-01-01')}
            maxDate={currentMonthEnd}
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} helperText={null} />}
        />
    </LocalizationProvider>
  );
};

export default MonthYearPicker;
