import { RiDeleteBin2Fill } from '@remixicon/react'
import { Button, Card, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@tremor/react'
import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import { DELETE_USER, GET_USER_LIST } from '../../services/usuarios/userServices';
import ConfirmationModal from '../shared/ConfirmationModal';
import Notification from '../shared/NotificationAlert';

const ListaUsuarios = () => {
  const { loading, error, data } = useQuery(GET_USER_LIST);
  const [deleteUser, { dataUser }] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USER_LIST }]
  });

  //Notification values
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedId, setSelectedId] = useState(null); // Nuevo estado para almacenar el ID seleccionado
  const [title, setTitle] = useState('');//Notification values
  const [showNotification, setShowNotification] = useState(false);

  const deleteUserById = (userId) => {
    deleteUser({ variables: { id: userId} })
      .then(result => {
        // Aquí puedes manejar el resultado si es necesario
        handleShowNotification(`El usuario fue eliminado con éxito.`)
      })
      .catch(error => {
        // Aquí puedes manejar errores si la mutación falla
        console.error('Error al eliminar usuario:', error);
      });
  };

  const handleShowNotification = (message) => {
    setMessage(message)
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000); // Oculta la notificación después de 2 segundos
  };

  const handleOpenConfirmModal = (title, message, id) => {
    setMessage(message)
    setTitle(title)
    setSelectedId(id); // Establece el ID seleccionado
    setConfirmModalOpen(true)
    /* handleClearStates() */
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleConfirmAction = (id) => {
    // Lógica para la acción de confirmación
    deleteUserById(id)
    handleCloseConfirmModal();
  };

  if (loading) return <Card className="mx-auto w-auto m-10">Cargando</Card>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <Card className="mx-auto w-auto m-10">
      <ConfirmationModal
        isOpen={confirmModalOpen}
        onClose={handleCloseConfirmModal}
        message={message}
        onConfirm={handleConfirmAction}
        onCancel={handleCloseConfirmModal}
        confirmText="Aceptar"
        cancelText="Cancelar"
        title={title}
        id={selectedId}
      />
      {showNotification && <Notification message={message} />}
      <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Lista de usuarios</h3>
        <Table>
            <TableHead>
                <TableRow>
                <TableHeaderCell>Nombre</TableHeaderCell>
                <TableHeaderCell className="text-right">
                    Correo electrónico
                </TableHeaderCell>
                <TableHeaderCell>Rol</TableHeaderCell>
                <TableHeaderCell>Eliminar</TableHeaderCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {data.listUsers.users.map((user) => (
                    <TableRow key={user.name}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell className="text-right">{user.email}</TableCell>
                        <TableCell>{user.role}</TableCell>
                        <TableCell>
                          {user.role !== 'master' && 
                            <Button 
                                className='bg-red-600 border-transparent hover:border-transparent hover:bg-red-700' 
                                icon={RiDeleteBin2Fill}
                                onClick={() => handleOpenConfirmModal('¿Deseas eliminar este usuario?', 'El usuario se eliminara permanentemente.', user._id)}
                            ></Button>
                          }
                        </TableCell>
                    </TableRow>

                ))}
            </TableBody>
        </Table>
    </Card>
  )
}

export default ListaUsuarios
