import { Switch, TextInput } from '@tremor/react';
import React, { useEffect, useState } from 'react';

const FichaTecnica = ({ tipo, residuo, onValuesChange, reset  }) => {
    const [formData, setFormData] = useState({
        operador: '',
        cantidad: '',
        cantTambos: '',
        cantidadMan: '',
        densidad: '',
        contenedor: '',
        derrames: false,
        });

    const unidad = residuo === 'agua' || residuo === 'aceite' ? 'Litros' : 'Kilos';

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: newValue });
      };

    const handleSwitchChange = (newValue) => {
        setFormData({ ...formData, derrames: newValue });
    };
    
    useEffect(() => {
        if (reset) {
            // Restablecer los valores del formulario si la prop reset es true
            setFormData({
                operador: '',
                cantidad: '',
                cantidadMan: '',
                densidad: '',
                contenedor: '',
                derrames: false,
                cantTambos: ''
            });
        }
    }, [reset]);

    useEffect(() => {
        if (onValuesChange) {
            onValuesChange(formData);
        }
    }, [formData, onValuesChange]);

  return (
    <div className="bg-white rounded-lg shadow p-6 mt-3">
        <div className="mb-4">
            <label htmlFor="operador" className="font-semibold mr-2">Nombre del operador:</label>
            <TextInput disabled={!residuo} placeholder="Ingrese nombre del operador.." type="text" id="operador" name="operador" value={formData.operador} onChange={handleInputChange} className="input border-b border-gray-300 w-1/2" />
        </div>
        <div className="mb-4 flex gap-5 justify-between">
            <div className="w-1/4">
                <label htmlFor="cantidad" className="font-semibold mr-2">* Cantidad:</label>
                <TextInput disabled={!residuo} placeholder="Ingrese la cantidad.." type="text" id="cantidad" name="cantidad" value={formData.cantidad} onChange={handleInputChange} className="input border-b border-gray-300" />
                {
                    (residuo === 'porrones_tinas' || residuo === 'filtro_de_aire') ?
                    <p className="text-sm text-gray-500">Cantidad por pieza</p> :
                    <p className="text-sm text-gray-500">Cantidad de {unidad}</p>
                }
            </div>
            { tipo &&
            <div className="w-1/4">
                <label htmlFor="tambos" className="font-semibold mr-2">* Cantidad de tambos:</label>
                <TextInput disabled={!residuo} placeholder="Ingrese cantidad de tambos.." type="text" id="cantTambos" name="cantTambos" value={formData.cantTambos} onChange={handleInputChange} className="input border-b border-gray-300" />
            </div>}
            <div className="w-1/4 pl-2">
                <label htmlFor="cantidadMan" className="font-semibold mr-2">Cantidad Man:</label>
                <TextInput disabled={!residuo} placeholder="Ingrese la cantidad manifestada.." type="text" id="cantidadMan" name="cantidadMan" value={formData.cantidadMan} onChange={handleInputChange} className="input border-b border-gray-300" />
                {
                    (residuo === 'porrones_tinas' || residuo === 'filtro_de_aire') ?
                    <p className="text-sm text-gray-500">Cantidad por pieza</p> :
                    <p className="text-sm text-gray-500">Cantidad de {unidad} manifestados</p>
                }
            </div>
            
            <div className="w-1/4 pr-2">
                <label htmlFor="densidad" className="font-semibold mr-2">Densidad:</label>
                <TextInput disabled={!residuo} placeholder="Ingrese la densidad.." type="text" id="densidad" name="densidad" value={formData.densidad} onChange={handleInputChange} className="input border-b border-gray-300" />
            </div>
        </div>
        <div className="mb-4 flex justify-between">
            <div className="w-1/3">
                <label htmlFor="contenedor" className="font-semibold mr-2"># de Contenedor:</label>
                <TextInput disabled={!residuo} placeholder="Ingrese el número del contenedor.." type="text" id="contenedor" name="contenedor" value={formData.contenedor} onChange={handleInputChange} className="input border-b border-gray-300" />
            </div>
            
            <div className="w-1/3 pl-2">
                <p className="font-semibold">Derrames ocasionados:</p>
                <div className="flex items-center mt-2 space-x-3">
                    <label htmlFor="derrames">No</label>
                    <Switch disabled={!residuo} id="derrames" name="derrames" checked={formData.derrames} onChange={handleSwitchChange} />
                    <label htmlFor="derrames">Sí</label>
                </div>
            </div>
            
        </div>
    </div>
  );
}

export default FichaTecnica;
