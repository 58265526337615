import React from 'react'
import { SearchSelect, SearchSelectItem, Title } from '@tremor/react';
import { useQuery } from '@apollo/client';
import { GET_COMPANIES_LIST } from '../../services/empresas/companyService';

const BuscarEmpresa = ({ onSeleccionChange, filtro }) => {
  const { loading, error, data } = useQuery(GET_COMPANIES_LIST);

  const empresasFiltradas = filtro
    ? data?.getCompanies.filter((empresa) => empresa.active)
    : data?.getCompanies;

    if (loading) return <h1>Cargando..</h1>;
    if (error) return <p>Error : {error.message}</p>;
  return (
    <div className='w-full md:w-1/3 '>
      <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Lista de empresas</Title>
      <SearchSelect className='mt-3' placeholder="Selecciona una empresa.." onValueChange={onSeleccionChange}>
        {empresasFiltradas.map((empresa) => (
          <SearchSelectItem 
            key={empresa._id} 
            value={empresa.name}
            onClick={() => onSeleccionChange(empresa)}
          />
        ))}
      </SearchSelect>

    </div>
  )
}

export default BuscarEmpresa
