import { RiDeleteBin2Fill, RiEdit2Line, RiHandCoinFill, RiHandCoinLine, RiImage2Line, RiMoneyDollarBoxLine, RiMoneyDollarCircleLine, RiRefreshLine, RiWalkLine, RiWallet2Line, RiWalletLine } from '@remixicon/react'
import { Badge, BadgeDelta, Button, Card, DateRangePicker, DateRangePickerItem, Select, SelectItem, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@tremor/react'
import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import ConfirmationModal from '../shared/ConfirmationModal';
import Notification from '../shared/NotificationAlert';
import { DELETE_FINANCE, GET_BALANCE, GET_FINANCE, GET_FINANCE_TOTAL } from '../../services/finanzas/finanzasService';
import moment from 'moment-timezone';
import { es } from 'date-fns/locale'
import MovimientosPanel from './MovimientosPanel';
import { GET_RECORDS } from '../../services/registros/registroService';
import EditFinanza from './EditFinanza';


const HistorialFinanzas = ({ updateTrigger }) => {
  const [variablesDate, setVariablesDateValue] = useState({
    startDate: moment().startOf('day').unix(),
    endDate: moment().endOf('day').unix()
  });

  const { loading, error, data, refetch } = useQuery(GET_FINANCE);
  const [deleteFinance, { dataUser }] = useMutation(DELETE_FINANCE, {
    refetchQueries: [
      { query: GET_FINANCE }, 
      { query: GET_BALANCE }, 
      {
        query: GET_FINANCE_TOTAL,
        variables: { startDate: variablesDate?.startDate, endDate: variablesDate?.endDate },
      },
      { query: GET_RECORDS }
    ]
  });

  const today = moment().startOf('day').toDate();
  const todayEnd = moment().endOf('day').toDate();

  const [value, setValue] = useState({
    from: today,
    to: todayEnd
  });

  //Notification values
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedId, setSelectedId] = useState(null); // Nuevo estado para almacenar el ID seleccionado
  const [title, setTitle] = useState('');//Notification values
  const [showNotification, setShowNotification] = useState(false);
  const [selectedScrap, setSelectedScrap] = useState(''); // Define el estado para almacenar el valor seleccionado del scrap
  const [isMovimientosOpen, setIsMovimientosOpen] = useState(false);
  //Edit values
  const [isEditarOpen, setIsEditarOpen] = useState(false);
  const [editData, setEditData] = useState();

  const friendlyNames = {
    aceite: 'Aceite',
    basura: 'Basura',
    agua: 'Agua',
    lodo: 'Lodo',
    porrones_tinas: 'Porrones / Tinas',
    filtro_de_aire: 'Filtro de Aire'
  };


  useEffect(() => {
    if (updateTrigger) {
      updateFiltro();
    }
  }, [updateTrigger]);
  
  const deleteFinanceById = (moveId) => {
    deleteFinance({ variables: { id: moveId} })
      .then(result => {
        // Aquí puedes manejar el resultado si es necesario
        handleShowNotification(`El movimiento fue eliminado con éxito.`)
        updateFiltro();
      })
      .catch(error => {
        // Aquí puedes manejar errores si la mutación falla
        console.error('Error al eliminar movimiento:', error);
      });
  };

  const handleValueChange = (newValue) => {
    // Establecer el valor 'to' como la última hora del día si se ha definido un 'to'
    if (newValue.to) {
      const endOfDay = moment(newValue.to).endOf('day').toDate();
      setValue({ from: newValue.from, to: endOfDay });
    } else {
      setValue(newValue);
    }
  };


  const handleShowNotification = (message) => {
    setMessage(message)
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000); // Oculta la notificación después de 2 segundos
  };

  const handleOpenConfirmModal = (title, message, id) => {
    setMessage(message)
    setTitle(title)
    setSelectedId(id); // Establece el ID seleccionado
    setConfirmModalOpen(true)
    /* handleClearStates() */
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleConfirmAction = (id) => {
    // Lógica para la acción de confirmación
    deleteFinanceById(id)
    handleCloseConfirmModal();
  };

  const handleEditFinance = (finance) => {
    setIsEditarOpen(true)
    setEditData(finance)
  }

  const formatDateTime = (timestamp) => {
    return moment.unix(timestamp).tz('America/Monterrey').format('DD/MM/YYYY');
  };

  // Maneja el cambio en la selección del scrap
  const handleScrapChange = (value) => {
    setSelectedScrap(value);
  };

  // Maneja la actualización de las fechas
  const updateFiltro = () => {
    const variables = {};

    if(!value.from || !value.to){
      handleShowNotification(`Falta definir: Del ${moment(value.from).tz('America/Monterrey').format('DD/MM/YYYY')} al ????`)
      return
    }

    if (value.from && value.to) {
      variables.startDate = moment(value.from).unix();
      variables.endDate = moment(value.to).unix();
    }
    if (selectedScrap !== null) {
      variables.scrap = selectedScrap;
    }

    setVariablesDateValue({ startDate: variables.startDate, endDate: variables.endDate });
    refetch(variables);
    handleShowNotification(`Se actualizaron los movimientos`)

  };

  if (loading) return <Card className="mx-auto w-full m-10">Cargando</Card>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div id='tableFinance' className='flex w-3/4'>
      <Card className=" ml-10 mb-10">
        <ConfirmationModal
          isOpen={confirmModalOpen}
          onClose={handleCloseConfirmModal}
          message={message}
          onConfirm={handleConfirmAction}
          onCancel={handleCloseConfirmModal}
          confirmText="Aceptar"
          cancelText="Cancelar"
          title={title}
          id={selectedId}
        />
        {showNotification && <Notification message={message} />}
        <div className="flex justify-between gap-10">
          <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Movimientos</h3>
          <div className="flex items-center space-x-2 mb-5 w-full gap-3">
            <div className="w-full md:w-3/5 mt-4 md:mt-0">
              <Select
                className=''
                placeholder={'Filtrar por residuo'}
                onChange={handleScrapChange}
                value={selectedScrap}
              >
                <SelectItem value="">Limpiar filtro</SelectItem>
                <SelectItem value="aceite">Aceite</SelectItem>
                <SelectItem value="basura">Basura</SelectItem>
                <SelectItem value="agua">Agua</SelectItem>
                <SelectItem value="lodo">Lodo</SelectItem>
                <SelectItem value="porrones_tinas">Porrones / Tinas</SelectItem>
                <SelectItem value="filtro_de_aire">Filtro de Aire</SelectItem>
              </Select>
            </div>
            <DateRangePicker
              className="mx-auto max-w-md"
              value={value}
              onValueChange={handleValueChange}
              locale={es} // Establecer el locale español
              selectPlaceholder="Seleccionar"
              placeholder="Selecciona un rango de fecha"
              maxDate={new Date()}
              enableClear={false}
              color="rose"
            >
              <DateRangePickerItem
                key="today"
                value="today"
                from={moment().startOf('day').toDate()} // Comenzar desde el principio del día actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Hoy
              </DateRangePickerItem>
              <DateRangePickerItem
                key="last7days"
                value="last7days"
                from={moment().subtract(7, 'days').startOf('day').toDate()} // Hace 7 días desde el principio del día actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Últimos 7 días
              </DateRangePickerItem>
              <DateRangePickerItem
                key="last30days"
                value="last30days"
                from={moment().subtract(30, 'days').startOf('day').toDate()} // Hace 30 días desde el principio del día actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Últimos 30 días
              </DateRangePickerItem>
              <DateRangePickerItem
                key="monthToDate"
                value="monthToDate"
                from={moment().startOf('month').toDate()} // Comenzar desde el principio del mes actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Del mes a la fecha
              </DateRangePickerItem>
              <DateRangePickerItem
                key="yearToDate"
                value="yearToDate"
                from={moment().startOf('year').toDate()} // Comenzar desde el principio del año actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Del año a la fecha
              </DateRangePickerItem>
            </DateRangePicker>
            <Button icon={RiRefreshLine} onClick={updateFiltro}></Button>
            <Button icon={RiImage2Line} onClick={() => setIsMovimientosOpen(true)}>
            </Button>
          </div>
        </div>
          <Table>
              <TableHead>
                  <TableRow>
                  <TableHeaderCell>Fecha</TableHeaderCell>
                  <TableHeaderCell>Tipo</TableHeaderCell>
                  <TableHeaderCell>Empresa</TableHeaderCell>
                  <TableHeaderCell>Descripción</TableHeaderCell>
                  <TableHeaderCell>Residuo</TableHeaderCell>
                  <TableHeaderCell>Entrada</TableHeaderCell>
                  <TableHeaderCell>Salida</TableHeaderCell>
                  <TableHeaderCell className="text-center align-middle">Acción</TableHeaderCell>
                  </TableRow>
              </TableHead>
              <TableBody>
              {data.getFinances.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    No hay registros del día de hoy.
                  </TableCell>
                </TableRow>
              ) : (
                data.getFinances.map((finance) => (
                  <TableRow key={finance._id}>
                      <TableCell>{ formatDateTime(finance.entry_date)}</TableCell>
                      <TableCell>
                          {finance.type === 'entrada' ? (
                            <BadgeDelta deltaType="increase">entrada</BadgeDelta>
                            ) : finance.type === 'prestamo' ? (
                            <Badge color='zinc' icon={RiMoneyDollarCircleLine}>prestamo</Badge>
                          ) : finance.type === 'comision' ? (<Badge color='purple' icon={RiWalletLine}>comisión</Badge>                            
                          ) : <BadgeDelta deltaType="decrease">gasto</BadgeDelta> }
                      </TableCell>
                      <TableCell>{finance.company.name}</TableCell>
                      <TableCell>{finance.description}</TableCell>
                      <TableCell>{friendlyNames[finance.scrap]}</TableCell>
                      <TableCell >${finance.entry}</TableCell>
                      <TableCell>${finance.exit}</TableCell>
                      <TableCell className='flex gap-2 justify-center'>
                        <Button 
                            className='bg-red-600 border-transparent hover:border-transparent hover:bg-red-700' 
                            icon={RiDeleteBin2Fill}
                            onClick={() => handleOpenConfirmModal('¿Deseas eliminar el movimiento?', 'El movimiento  se eliminara permanentemente.', finance._id)}
                        ></Button>
                        <Button 
                            icon={RiEdit2Line}
                            onClick={() => handleEditFinance(finance)}
                        ></Button>
                      </TableCell>
                  </TableRow>
                  ))
                )}
              </TableBody>
          </Table>
      </Card>
      <MovimientosPanel
        isOpen={isMovimientosOpen}
        setIsOpen={setIsMovimientosOpen}
        data={data}
        variablesDate={variablesDate}
      />
      <EditFinanza 
        editData={editData} 
        isEditarOpen={isEditarOpen}
        setIsEditarOpen={setIsEditarOpen}
      />
    </div>
  )
}

export default HistorialFinanzas
