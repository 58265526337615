import React from 'react'
import PageHeader from '../components/sidebar/PageHeader'
import DashboardBase from '../components/tablero/DashboardBase'

const Tablero = () => {
  return (
    <div className='w-full'>
      <PageHeader pageTitle="Tablero" />
      <DashboardBase />
    </div>
  )
}

export default Tablero
