import { Button, Card, DatePicker, Select, SelectItem, Switch, TextInput, Title } from '@tremor/react'
import { es } from 'date-fns/locale'
import React, { useState } from 'react'
import { ADD_MANIFIESTO, GET_MANIFIESTOS } from '../../services/manifiestos/manifiestosService';
import { useMutation, useQuery } from '@apollo/client';
import { RiAddLine, RiRefreshLine } from '@remixicon/react';
import moment from 'moment';
import Notification from '../shared/NotificationAlert';
import { GET_RECORDS } from '../../services/registros/registroService';

const CrearManifiesto = () => {
    const [selectedScrap, setSelectedScrap] = useState('');
    const [addManifiesto, { dataManifiestos }] = useMutation(ADD_MANIFIESTO, {
        refetchQueries: [
            {
                query: GET_MANIFIESTOS,
                variables: { residuo: selectedScrap  === "aceite" ? selectedScrap : "resto"},
            },
            { query: GET_RECORDS },
        ]
      });
    const fechaEntrada = moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();// Definir fechaEntrada
    const [selectedDate, setSelectedDate] = useState(fechaEntrada);
    const [razonSocialValue, setRazonSocial] = useState('');
    const [liquidosValue, setLiquidos] = useState('');
    const [solidosValue, setSolidos] = useState('');
    const [transportistaValue, setTransportista] = useState('MARCO ANTONIO CIRIO OLIVARES');
    const [noTransportistaValue, setNoTransportista] = useState('19-I-012D-19');
    const [destinoFinalValue, setDestinoFinal] = useState('');
    const [noManifiestoValue, setNoManifiesto] = useState('');
    const [noAutoDestinoFinalValue, setNoAutoDestinoFinal] = useState('');
    //Notification values
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('');

    const handleRazonSocial = (razonSocial) => {
        setRazonSocial(razonSocial)
    }
    const handleScrapChange = (residuo) => {
        setSelectedScrap(residuo)
    }
    const handleLiquidosChange = (cantidad) => {
        setLiquidos(cantidad)
    }
    const handleSolidosChange = (cantidad) => {
        setSolidos(cantidad)
    }
    const handleTransportistaChange = (nombre) => {
        setTransportista(nombre)
    }
    const handleNoTransportistaChange = (numero) => {
        setNoTransportista(numero)
    }
    const handleDestinoFinalChange = (destino) => {
        setDestinoFinal(destino)
    }
    const handleNoManifiestoChange = (numero) => {
        setNoManifiesto(numero)
    }
    const handleNoAutoDestinoFinalChange = (numero) => {
        setNoAutoDestinoFinal(numero)
    }

    const handleClearStates = () => {
        setNoManifiesto('');
        setRazonSocial('');
        setSelectedScrap('');
        setLiquidos('');
        setSolidos('');
        setDestinoFinal('');
        setNoAutoDestinoFinal('');
    }

    const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000); // Oculta la notificación después de 2 segundos
      };

    const handleAddManifiesto = () => {
        const densidadValue = '0.9';
        const cantidadLts = (densidadValue * liquidosValue).toString();
        const cantidadResiduo = cantidadLts;
        addManifiesto({ 
          variables: { 
            fechaEntrada: moment(selectedDate).unix(),
            noManifiesto: noManifiestoValue,
            razonSocial: razonSocialValue,
            estado: '19',
            residuo: selectedScrap,
            toxico:'x',
            inflamable: 'x',
            densidad: '0.9',
            cantidadLts: cantidadLts,
            cantidadKg: solidosValue,
            fase: 'RECICLAJE',
            transportista: transportistaValue,
            noAutorizacionTransportista: noTransportistaValue,
            cantidadResiduo: cantidadResiduo,
            manifiesto: "30",
            destinoFinal: destinoFinalValue,
            numeroManifiesto: noManifiestoValue,
            noAutorizacionDestinoFinal: noAutoDestinoFinalValue,
            tipo: 'ENTRADA'
          } 
        }).then(result => {
            // Aquí puedes manejar el resultado si es necesario
            handleShowNotification(`Se creó el manifiesto  ${noManifiestoValue} con éxito.`)
            handleClearStates()
        }).catch(error => {
            // Obtener el mensaje de error del objeto error
            const errorMessage = error.message;
            console.log(errorMessage)
            handleShowNotification(errorMessage, 'Oops falló algo!')
          });
      };

  return (
    <Card>
        <div className='flex justify-between'>
            <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mb-2">Crear manifiesto</Title>
            <DatePicker
                lang='es' // Establece el idioma como español
                className="w-1/6 justify-end"
                value={selectedDate}
                selected={selectedDate}
                onValueChange={(date) => {
                    // Mantener la hora fija a las 12:00 en cualquier cambio
                    const adjustedDate = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
                    setSelectedDate(adjustedDate);
                }}
                locale={es} // Pasa el locale español
                placeholder="Seleccionar"
                maxDate={new Date()} // Establece la fecha máxima como hoy
                enableClear={false} // Deshabilita la opción de borrar la fecha
            />
        </div>
        <div className='flex flex-col gap-5 justify-between mt-5'>
            <div className='flex gap-5'>
                <div className="w-1/3">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">No. Manifiesto</h3>
                    <TextInput placeholder="Ingresa cantidad.." value={noManifiestoValue} onValueChange={handleNoManifiestoChange}/>
                </div>
                <div className="w-1/3">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Razón social</h3>
                    <TextInput placeholder="Ingresa razón social.." value={razonSocialValue} onValueChange={handleRazonSocial}/>
                </div>
                <div className="w-1/3">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Residuo</h3>
                    <Select
                        className=''
                        placeholder={'Selecciona residuo'}
                        onChange={handleScrapChange}
                        value={selectedScrap}
                    >
                        <SelectItem value="aceite">Aceite</SelectItem>
                        <SelectItem value="basura">Basura</SelectItem>
                        <SelectItem value="agua">Agua</SelectItem>
                        <SelectItem value="lodo">Lodo</SelectItem>
                        <SelectItem value="porrones_tinas">Porrones / Tinas</SelectItem>
                        <SelectItem value="filtro_de_aire">Filtro de Aire</SelectItem>
                    </Select>
                </div>
            </div>
            <div className='flex gap-5'>
                <div className="w-1/3">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Liquidos (T/200/ LTS)</h3>
                    <TextInput placeholder="Ingresa cantidad.." value={liquidosValue} onValueChange={handleLiquidosChange} />
                </div>
                <div className="w-1/3">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Solidos (Kilos)</h3>
                    <TextInput placeholder="Ingresa cantidad.." value={solidosValue} onValueChange={handleSolidosChange} />
                </div>
                <div className="w-1/3">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Kilos registrados en el manifiesto</h3>
                    <TextInput placeholder="Ingresa cantidad.." value={0.9 * liquidosValue} readOnly />
                </div>
            </div>
            <div className='flex gap-5'>
                <div className="w-1/2">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Nombre del transportista</h3>
                    <TextInput placeholder="Ingresa nombre transportista.." value={transportistaValue} onValueChange={handleTransportistaChange}/>
                </div>
                <div className="w-1/2">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Num. autorización transportista</h3>
                    <TextInput placeholder="Ingresa no. autorización transportista.." value={noTransportistaValue} onValueChange={handleNoTransportistaChange}/>
                </div>
            </div>
            <div className='flex gap-5'>
                <div className="w-1/2">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Destino final</h3>
                    <TextInput placeholder="Ingresa destino final.." value={destinoFinalValue} onValueChange={handleDestinoFinalChange}/>
                </div>
                <div className="w-1/2">
                    <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">No. autorización destino final</h3>
                    <TextInput placeholder="Ingresa destino final.." value={noAutoDestinoFinalValue} onValueChange={handleNoAutoDestinoFinalChange}/>
                </div>
            </div>
        </div>
        <div className='flex w-full md:w-auto flex-col justify-center md:justify-end md:flex-row gap-3 mt-7 md:mt-5'>
            <Button 
                className='bg-slate-500 border-transparent hover:border-transparent hover:bg-slate-700' 
                icon={RiRefreshLine}
                onClick={() => handleClearStates()}
                > Limpiar
            </Button>
            <Button 
                className='bg-[#38B866] border-transparent hover:border-transparent hover:bg-green-600' 
                icon={RiAddLine}
                onClick={() => handleAddManifiesto()}
                /* disabled={!isButtonEnabled} */
                >Agregar manifiesto
            </Button>
        </div>
        {showNotification && <Notification message={message} />}
    </Card>
  )
}

export default CrearManifiesto
