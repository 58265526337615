import { RiDownload2Fill } from '@remixicon/react'
import { Dialog, BadgeDelta, Button, Card, DateRangePicker, DateRangePickerItem, Select, SelectItem, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, DialogPanel } from '@tremor/react'
import moment from 'moment';
import TotalFinanzas from './TotalFinanzas';
import html2canvas from 'html2canvas';
import { useState } from 'react';

const MovimientosPanel = ({ isOpen, setIsOpen, data, variablesDate }) => {
    const [imageUrl, setImageUrl] = useState('');

    const captureAndDownloadImage = (elementId) => {
      const element = document.getElementById(elementId);
  
    html2canvas(element)
        .then((canvas) => {
            // Convertir el canvas a una URL de imagen
            const capturedImageUrl = canvas.toDataURL('image/png');
            // Establecer la URL de la imagen en el estado
            setImageUrl(capturedImageUrl);

            // Crear un enlace temporal para descargar la imagen
            const link = document.createElement('a');
            link.href = capturedImageUrl;
            link.download = 'captured_image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const friendlyNames = {
        aceite: 'Aceite',
        basura: 'Basura',
        agua: 'Agua',
        lodo: 'Lodo',
        porrones_tinas: 'Porrones / Tinas',
        filtro_de_aire: 'Filtro de Aire'
    };
    

    const formatDateTime = (timestamp) => {
        return moment.unix(timestamp).tz('America/Monterrey').format('DD/MM/YYYY');
      };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      static={true}
      className="z-[100]"
    >  
        <DialogPanel className="max-w-full">
            <Button onClick={() => captureAndDownloadImage('movimientos')} variant="secondary" className="flex items-center" icon={RiDownload2Fill}>
                Tomar captura          
            </Button>
            <div id='movimientos'>
                <div className="m-6 w-full">
                    <div className='flex items-center justify-between mb-4'>
                        <h2 className="text-lg font-semibold">Movimientos</h2>
                    </div>
            
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableHeaderCell>Fecha</TableHeaderCell>
                            <TableHeaderCell>Tipo</TableHeaderCell>
                            <TableHeaderCell>Empresa</TableHeaderCell>
                            <TableHeaderCell>Descripción</TableHeaderCell>
                            <TableHeaderCell>Residuo</TableHeaderCell>
                            <TableHeaderCell>Entrada</TableHeaderCell>
                            <TableHeaderCell>Salida</TableHeaderCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.getFinances.map((finance) => (
                                <TableRow key={finance._id}>
                                    <TableCell>{ formatDateTime(finance.entry_date)}</TableCell>
                                    <TableCell>
                                        {finance.type === 'entrada' || finance.type === 'cobro' ? 'Entrada' : 'Gasto' }
                                    </TableCell>
                                    <TableCell>{finance.company.name}</TableCell>
                                    <TableCell>{finance.description}</TableCell>
                                    <TableCell>{friendlyNames[finance.scrap]}</TableCell>
                                    <TableCell >${finance.entry}</TableCell>
                                    <TableCell>${finance.exit}</TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                    <div className='flex justify-center m-6'>
                        <TotalFinanzas variablesDate={variablesDate}/>
                    </div>
                </div>
            </div>
            <Button
                variant="light"
                className="mx-auto flex items-center"
                onClick={() => setIsOpen(false)}
                >
                Cerrar
            </Button>
        </DialogPanel>
    </Dialog>
  )
}

export default MovimientosPanel
