import { RiAddLine, RiRefreshLine } from '@remixicon/react';
import { Button, Card, Select, SelectItem, Switch, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput, Title } from '@tremor/react';
import React, { useState } from 'react';
import InputNumber from '../shared/InputNumber';
import { ADD_COMPANY, GET_COMPANIES_LIST } from '../../services/empresas/companyService';
import { useMutation } from '@apollo/client';
import ErrorModal from '../ErrorModal';
import Notification from '../shared/NotificationAlert';

const initialScraps = {
    aceite: 0,
    basura: 0,
    basura_tipo: 'KG',
    agua: 0,
    lodo: 0,
    lodo_tipo: 'KG',
    aceite_tipo_cargo: 'cobro',
    agua_tipo_cargo: 'cobro',
    basura_tipo_cargo: 'cobro',
    lodo_tipo_cargo: 'cobro',
    porrones_tinas: 0,
    porrones_tinas_tipo_cargo: 'cobro',
    filtro_de_aire: 0,
    filtro_de_aire_tipo_cargo: 'cobro',
};

const AgregarEmpresa = () => {
    const [addCompany, { data }] = useMutation(ADD_COMPANY, {
        refetchQueries: [{ query: GET_COMPANIES_LIST }]
    });

    const [scrapsValue, setScraps] = useState(initialScraps);
    const [nameCompanyValue, setNameCompany] = useState('');
    const [contactValue, setContact] = useState('');
    const [phoneValue, setPhone] = useState('');
    const [typeValue, setTypeValue] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('');

    const handleValueChange = (inputName, newValue) => {
        setScraps(prevValues => ({ ...prevValues, [inputName]: newValue }));
    };

    const handleSwitchChange = (inputName, trueValue, falseValue) => {
        setScraps(prevValues => ({
            ...prevValues,
            [inputName]: prevValues[inputName] === trueValue ? falseValue : trueValue
        }));
    };

    const handlePorronesTinasChange = (newValue) => {
        setScraps(prevState => ({
            ...prevState,
            porrones_tinas: newValue
        }));
    };
    
    const handleFiltroDeAireChange = (newValue) => {
        setScraps(prevState => ({
            ...prevState,
            filtro_de_aire: newValue
        }));
    };
    
    const handlePorronesTinasTipoCargoChange = () => {
        const newPorronesTinasTipoCargoValue = scrapsValue.porrones_tinas_tipo_cargo === 'cobro' ? 'pago' : 'cobro';
        setScraps(prevState => ({
            ...prevState,
            porrones_tinas_tipo_cargo: newPorronesTinasTipoCargoValue
        }));
    };
    
    const handleFiltroDeAireTipoCargoChange = () => {
        const newFiltroDeAireTipoCargoValue = scrapsValue.filtro_de_aire_tipo_cargo === 'cobro' ? 'pago' : 'cobro';
        setScraps(prevState => ({
            ...prevState,
            filtro_de_aire_tipo_cargo: newFiltroDeAireTipoCargoValue
        }));
    };

    const handleInputChange = (setter) => (value) => setter(value);

    const handleClearStates = () => {
        setNameCompany('');
        setContact('');
        setPhone('');
        setTypeValue('');
        setScraps(initialScraps);
    };

    const handleAddCompany = () => {
        // Convertir los valores de scraps que deben ser numéricos
        const numericScraps = {
            ...scrapsValue,
            aceite: parseFloat(scrapsValue.aceite),
            basura: parseFloat(scrapsValue.basura),
            agua: parseFloat(scrapsValue.agua),
            lodo: parseFloat(scrapsValue.lodo),
            porrones_tinas: parseFloat(scrapsValue.porrones_tinas),
            filtro_de_aire: parseFloat(scrapsValue.filtro_de_aire),
        };

        addCompany({
            variables: {
                name: nameCompanyValue,
                scraps: numericScraps,
                type: typeValue,
                contact: contactValue,
                phone: phoneValue,
            }
        })
            .then(() => {
                handleShowNotification(`La empresa ${nameCompanyValue} se agregó éxitosamente.`);
                handleClearStates();
            })
            .catch(error => {
                handleOpenError(error.message);
            });
    };

    const handleShowNotification = (message) => {
        setMessage(message);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 2000);
    };

    const handleOpenError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
    };

    const handleCloseError = () => {
        setErrorOpen(false);
    };
    return (
        <div className='mt-5'>
            <div className='flex flex-col md:flex-row mt-5 md:gap-5'>
                {showNotification && <Notification message={message} />}
                <Card className="w-full md:w-1/3">
                    <ErrorModal isOpen={errorOpen} onClose={handleCloseError} errorMessage={errorMessage} />
                    <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Datos de la empresa</Title>
                    <div className='flex flex-col mt-5'>
                        <div className='flex flex-col w-full mb-5 gap-2'>
                            <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Nombre de la empresa</h3>
                            <TextInput placeholder="Ingresa nombre de la empresa.." value={nameCompanyValue} onValueChange={handleInputChange(setNameCompany)} className="mt-2" />
                            <p className="text-center md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Contacto</p>
                            <TextInput placeholder="Nombre de contacto.." value={contactValue || ''} onValueChange={handleInputChange(setContact)} />
                            <p className="text-center md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Teléfono de contacto</p>
                            <TextInput placeholder="Número de contacto.." value={phoneValue || ''} onValueChange={handleInputChange(setPhone)} />
                            <p className="text-center md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Tipo</p>
                            <Select placeholder='Selecciona un tipo..' defaultValue={typeValue} value={typeValue} onChange={setTypeValue}>
                                <SelectItem value="empresa">Empresa</SelectItem>
                                <SelectItem value="recolectora">Recolectora</SelectItem>
                            </Select>
                        </div>
                    </div>
                </Card>
                <Card className='w-full md:w-2/3 mt-5 md:mt-0'>
                    <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Tabulador</Title>
                    <Table className='mt-5'>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Residuo</TableHeaderCell>
                                <TableHeaderCell>Tabulador</TableHeaderCell>
                                <TableHeaderCell>Unidad</TableHeaderCell>
                                <TableHeaderCell>Tipo de cargo</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {['aceite','agua', 'basura', 'lodo', 'porrones_tinas', 'filtro_de_aire'].map((residuo, index) => (
                                <TableRow key={index}>
                                    <TableCell>{residuo === 'porrones_tinas' ? 'Porrones / Tinas' : residuo === 'filtro_de_aire' ? 'Filtro de Aire' : residuo.charAt(0).toUpperCase() + residuo.slice(1)}</TableCell>
                                    <TableCell>
                                        {residuo === 'porrones_tinas' || residuo === 'filtro_de_aire' ? (
                                            <InputNumber
                                                initialValue={scrapsValue[residuo]}
                                                value={scrapsValue[residuo]}
                                                onValueChange={newValue => residuo === 'porrones_tinas' ? handlePorronesTinasChange(newValue) : handleFiltroDeAireChange(newValue)}
                                            />
                                        ) : (
                                            <InputNumber
                                                initialValue={scrapsValue[residuo]}
                                                value={scrapsValue[residuo]}
                                                onValueChange={newValue => handleValueChange(residuo, newValue)}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {residuo === 'basura' || residuo === 'lodo' ? (
                                            <div className='flex'>
                                                <label htmlFor={`switch${residuo}`} className={`mr-2 ${scrapsValue[`${residuo}_tipo`] === 'KG' ? 'font-semibold' : ''}`}>KG</label>
                                                <Switch
                                                    name={`switch${residuo}`}
                                                    className='items-center'
                                                    checked={scrapsValue[`${residuo}_tipo`] === 'TAMBO'}
                                                    onChange={() => handleSwitchChange(`${residuo}_tipo`, 'TAMBO', 'KG')}
                                                />
                                                <label htmlFor={`switch${residuo}`} className={`ml-2 ${scrapsValue[`${residuo}_tipo`] === 'TAMBO' ? 'font-semibold' : ''}`}>TAMBO</label>
                                            </div>
                                        ) : residuo === 'filtro_de_aire' || residuo === 'porrones_tinas' ? (
                                            <span>Por pieza</span>
                                        ) : (
                                            <span>Litros</span>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div className='flex items-center mb-2'>
                                            <label htmlFor={`switchTipoCargo${residuo}`} className={`mr-2 ${scrapsValue[`${residuo}_tipo_cargo`] === 'cobro' ? 'font-semibold' : ''}`}>Cobro</label>
                                            <Switch
                                                name={`switchTipoCargo${residuo}`}
                                                className='items-center'
                                                checked={scrapsValue[`${residuo}_tipo_cargo`] === 'pago'}
                                                onChange={() => {
                                                    if (residuo === 'porrones_tinas') {
                                                        handlePorronesTinasTipoCargoChange();
                                                    } else if (residuo === 'filtro_de_aire') {
                                                        handleFiltroDeAireTipoCargoChange();
                                                    } else {
                                                        handleSwitchChange(`${residuo}_tipo_cargo`, 'pago', 'cobro');
                                                    }
                                                }}
                                            />
                                            <label htmlFor={`switchTipoCargo${residuo}`} className={`ml-2 ${scrapsValue[`${residuo}_tipo_cargo`] === 'pago' ? 'font-semibold' : ''}`}>Pago</label>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Card>
            </div>
            <div className='flex w-full md:w-auto flex-col justify-center md:justify-end md:flex-row gap-2 my-3 md:mt-5'>
                <Button
                    className='bg-slate-500 border-transparent hover:border-transparent hover:bg-slate-700'
                    icon={RiRefreshLine}
                    onClick={handleClearStates}
                > Limpiar
                </Button>
                <Button
                    className='bg-[#38B866] border-transparent hover:border-transparent hover:bg-green-600' 
                    icon={RiAddLine}
                    onClick={() => handleAddCompany()}
                    >Agregar
                </Button>
            </div>
    </div>
  )
}

export default AgregarEmpresa
