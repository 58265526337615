import React, {useEffect, useState} from 'react'
import CardGridMap from './CardGridMap';
import ChartDonut from '../ChartDonut';
import BarChartHero from '../SalesBarChart';
import { Tab, TabGroup, TabList, TabPanel, TabPanels, Card, } from '@tremor/react';
import { es } from 'date-fns/locale'
import { useQuery } from '@apollo/client';
import { GET_CALCULATIONS_COMPANY, GET_CALCULATIONS_SCRAPS, GET_CALCULATIONS_TYPE } from '../../services/registros/registroService';
import moment from 'moment/moment';
import CalculationsBy from './CalculationsBy';
import CalculationsCards from './CalculationsCards';
import { GET_CALCULATIONS_BY } from '../../services/tablero/TableroService';
import TableBy from './TableBy';
import TableByType from './TableByType';
import MonthYearPicker from '../shared/MonthYearPicker';
import TableByCompanies from './TableByCompanies';
const DashboardBase = () => {
  const currentMonth = new Date().getMonth() + 1;

  const [month, setSelectedMonth] = useState(currentMonth);

  const { data: dataBy, loading: loadingByMonth, refetch } = useQuery(GET_CALCULATIONS_BY, {
    variables: { month },
  });

  useEffect(() => {
    if (month !== null) {
      refetch({ month });
    }
  }, [month, refetch]);

  const handleMonthChange = (month) => {
    console.log(month)
    setSelectedMonth(month);
  };

  /* const { data: dataCompany, loading: loadingCompany } = useQuery(GET_CALCULATIONS_COMPANY, {
    variables: { startDate, endDate },
  });
  const { data: dataScraps, loading: loadingScraps } = useQuery(GET_CALCULATIONS_SCRAPS, {
    variables: { startDate, endDate },
  });
  const { data: dataType, loading: loadingType } = useQuery(GET_CALCULATIONS_TYPE, {
    variables: { startDate, endDate },
  }); */

  const scraps = [...new Set(dataBy?.getCalculations?.byScrap.map(entry => entry.scrap))];
  const companies = [...new Set(dataBy?.getCalculations?.byCompany.map(companies => companies.scrap))];
  const weekly = [...new Set(dataBy?.getCalculations?.byType.map(weekly => weekly.week))];

  const byScrap = scraps.reduce((acc, scrap) => {
    acc[scrap] = dataBy?.getCalculations?.byScrap.filter(entry => entry.scrap === scrap);
    return acc;
    }, {});

  const byCompany = companies.reduce((acc, scrap) => {
    acc[scrap] = dataBy?.getCalculations?.byCompany.filter(companies => companies.scrap === scrap);
    return acc;
    }, {});
  
  const byWeek = weekly.reduce((acc, week) => {
    acc[week] = dataBy?.getCalculations?.byType.filter(weekly => weekly.week === week);
    return acc;
    }, {});

  return (
    <div className='bg-slate-200 mx-10'>
        <div className='flex flex-col gap-5 mt-4'>
          <TableByType dataBy={byWeek}/> 
          <TabGroup>
            <div className='flex justify-between items-center'>
              <div>
                <TabList>
                  <Tab>Tablero por reisudos</Tab>
                  <Tab>Tablero por compañias</Tab>
                </TabList>
              </div>
              <Card className='w-1/7 p-3 flex justify-end'>
                <MonthYearPicker onMonthChange={handleMonthChange} />
              </Card>
            </div>
            <TabPanels>
              <TabPanel>
                <TableBy dataBy={byScrap}/>
              </TabPanel>
              <TabPanel>
                <TableByCompanies dataBy={byCompany}/>      
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
    </div>
  )
}

export default DashboardBase
