import React from 'react'

const PageHeader = ({ pageTitle }) => {
    return (
      <header className='flex flex-col m-10 mb-0 gap-4'>
        <p className='text-3xl font-bold'>{pageTitle}</p>
        <hr className='h-0.5 bg-seconday-grey'/>
      </header>
    );
  };
  
  export default PageHeader;