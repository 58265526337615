import { gql } from '@apollo/client';

export const  GET_CALCULATIONS_BY = gql`
  query GetCalculations($month: Int) {
    getCalculations(month: $month) {
      byScrap {
        week
        period
        scrap
        entry
        exit
      }
      byCompany {
        week
        period
        scrap
        entry
        exit
        company
      }
      byType {
        week
        period
        entry
        exit
      }
    }
  }
`;
