import React, { useState } from 'react'
import PageHeader from '../components/sidebar/PageHeader'
import EditarEmpresa from '../components/empresas/EditarEmpresa';
import AgregarEmpresa from '../components/empresas/AgregarEmpresa';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@tremor/react';
import { RiAddLine } from '@remixicon/react';

const Empresas = () => {

    
  return (
    <div className='w-full'>
        <PageHeader pageTitle="Empresas" />
        <TabGroup className="w-auto mx-10">
          <TabList className="mt-4">
            <Tab>Lista de empresas</Tab>
            <Tab>Agregar empresa</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <EditarEmpresa />

            </TabPanel>
            <TabPanel>
              <AgregarEmpresa />

            </TabPanel>
          </TabPanels>
        </TabGroup>
    </div>
  )
}

export default Empresas
