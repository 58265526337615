import React from 'react'
import PageHeader from '../components/sidebar/PageHeader'

const NoPage = () => {
  return (
    <div className='w-full'>
      <PageHeader pageTitle="No se encontró la pagína" />
    </div>
  )
}

export default NoPage
