import React from 'react'
import { NavLink } from '../sidebar/NavLink'
import logoCIBA from '../../assets/Logo-w.png'
import { useAuth0 } from '@auth0/auth0-react';
import { RiBankLine, RiBuilding4Line, RiEdit2Line, RiLogoutBoxLine, RiPieChart2Line, RiStackLine, RiUser3Line } from '@remixicon/react';
import { Button, Icon } from '@tremor/react';


const SideBar = () => {
  const {
    isAuthenticated,
    logout,
    user
  } = useAuth0();  

  return (
    <div className='flex w-1/6 gap-5 flex-col justify-between sticky top-0 bg-primary-blue h-max h-screen'>
      <div className='flex flex-col justify-center items-center bg-secondary-blue rounded-b-xl'>
        <img src={logoCIBA} alt="logo"  className='object-contain h-24 w-40 m-5'/>
        <hr className='w-4/5'/>
        <div className='flex gap-5 m-5 items-center'>
          <Icon icon={RiUser3Line} variant="simple" size="xl" className="w-10 h-10 text-primary-green"/>
          <div className='flex flex-col items-start text-white'>
              <p><b>{user.nickname}</b></p>
              <p>Administrador</p>
          </div>
        </div>
      </div>  
      <nav>
        <ul className='flex flex-col gap-5 w-auto'>
          <li className='h-auto'>
            <NavLink to='/'> 
              <Icon icon={RiBankLine} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Finanzas</p>
            </NavLink>
          </li>
          <li>
            <NavLink to='/tablero'>
              <Icon icon={RiPieChart2Line} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Tablero</p>
            </NavLink>
          </li>
          <li>
            <NavLink to='/registro'>
              <Icon icon={RiEdit2Line} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Registro</p>
            </NavLink>
          </li>
          <li>
            <NavLink to='/manifiestos'>
              <Icon icon={RiStackLine} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Manifiestos</p>
            </NavLink>
          </li>
          <li>
            <NavLink to='/usuarios'>
              <Icon icon={RiUser3Line} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Usuarios</p>
            </NavLink>
          </li>
          <li>
            <NavLink to='/empresas'>
              <Icon icon={RiBuilding4Line} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Empresas</p>
            </NavLink>
          </li>
        </ul>
      </nav>
        <div className='flex items-end justify-center gap-2 m-4 flex-grow'>
          { isAuthenticated && (
          <Button className='text-white font-bold hover:text-slate-300 bg-transparent border-transparent hover:border-transparent hover:bg-transparent'
            icon={RiLogoutBoxLine} 
            onClick={() => logout({ returnTo: window.location.origin })}
            >Cerrar sesión
          </Button>)}
        </div>
    </div>
  )
}

export default SideBar
