import React from 'react'
import {
    BadgeDelta,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
} from "@tremor/react";

const TableByCompanies = ({ dataBy }) => {
  if (!dataBy || Object.keys(dataBy).length === 0) {
    return (
      <div className="my-5">
        <Card>
          <Text>No hay datos disponibles en este mes.</Text>
        </Card>
      </div>
    );
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatString = (string) => {
    return string
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="my-5">
      {Object.keys(dataBy).map((residueKey) => {
        const residueData = dataBy[residueKey];

        const allWeeks = Array.from(
          new Set(residueData.map((item) => item.week))
        );

        const calculateCompanyWeeklySums = (type) => {
          const companySums = {};
          residueData.forEach((item) => {
            if (!companySums[item.company]) {
              companySums[item.company] = {};
            }
            if (!companySums[item.company][item.week]) {
              companySums[item.company][item.week] = 0;
            }
            companySums[item.company][item.week] += item[type];
          });
          return companySums;
        };

        const companyEntrySums = calculateCompanyWeeklySums("entry");
        const companyExitSums = calculateCompanyWeeklySums("exit");

        const calculateCompanyTotalSums = (type) => {
          const companyTotalSums = {};
          residueData.forEach((item) => {
            if (!companyTotalSums[item.company]) {
              companyTotalSums[item.company] = 0;
            }
            companyTotalSums[item.company] += item[type];
          });
          return companyTotalSums;
        };

        const companyTotalEntrySums = calculateCompanyTotalSums("entry");
        const companyTotalExitSums = calculateCompanyTotalSums("exit");

        return (
          <div key={residueKey} className="mb-8">
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>
                      <BadgeDelta
                        deltaType="increase"
                        isIncreasePositive={true}
                      >
                        {formatString(residueKey)} - Ingresos
                      </BadgeDelta>
                    </TableHeaderCell>
                    {allWeeks.map((week) => (
                      <TableHeaderCell key={week}>
                        {capitalizeFirstLetter(week)}
                      </TableHeaderCell>
                    ))}
                    <TableHeaderCell>Total</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(companyEntrySums).map((company) => (
                    <TableRow key={company}>
                      <TableCell>{company}</TableCell>
                      {allWeeks.map((week) => (
                        <TableCell key={week}>
                          {companyEntrySums[company][week] ? (
                            <Text>
                              $
                              {companyEntrySums[company][week].toLocaleString(
                                "en-US"
                              )}
                            </Text>
                          ) : (
                            <Text>$0</Text>
                          )}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Text>
                          ${companyTotalEntrySums[company].toLocaleString("en-US")}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
            <Card className="mt-8">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>
                      <BadgeDelta
                        deltaType="decrease"
                        isIncreasePositive={true}
                      >
                        {formatString(residueKey)} - Gastos
                      </BadgeDelta>
                    </TableHeaderCell>
                    {allWeeks.map((week) => (
                      <TableHeaderCell key={week}>
                        {capitalizeFirstLetter(week)}
                      </TableHeaderCell>
                    ))}
                    <TableHeaderCell>Total</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(companyExitSums).map((company) => (
                    <TableRow key={company}>
                      <TableCell>{company}</TableCell>
                      {allWeeks.map((week) => (
                        <TableCell key={week}>
                          {companyExitSums[company][week] ? (
                            <Text>
                              $
                              {companyExitSums[company][week].toLocaleString(
                                "en-US"
                              )}
                            </Text>
                          ) : (
                            <Text>$0</Text>
                          )}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Text>
                          ${companyTotalExitSums[company].toLocaleString("en-US")}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </div>
        );
      })}
    </div>
  );
};
  

export default TableByCompanies
