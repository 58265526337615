import { useMutation } from '@apollo/client';
import { RiAddLine, RiRefreshLine } from '@remixicon/react'
import { Button, Card, Select, SelectItem, TextInput } from '@tremor/react'
import React, { useState } from 'react'
import { ADD_USER, GET_USER_LIST } from '../../services/usuarios/userServices';
import Notification from '../shared/NotificationAlert';

const AgregarUsuario = () => {
    const [addUser, { data }] = useMutation(ADD_USER, {
        refetchQueries: [{ query: GET_USER_LIST }]
    });

    const [nameValue, setNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [roleValue, setRoleValue] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    //Notification values
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('');

    const handleShowNotification = (message) => {
      setMessage(message)
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Oculta la notificación después de 2 segundos
    };

    const handleNameChange = (value) => {
        setNameValue(value);
        validateForm(value, emailValue, roleValue);
    };

    const handleEmailChange = (value) => {
        setEmailValue(value);
        validateForm(nameValue, value, roleValue);
    };
    const handleRoleChange = (value) => {
        setRoleValue(value);
        validateForm(nameValue, emailValue, value);
        
    };
    
    const handleClearStates = () => {
      handleNameChange('');
      handleEmailChange('');
      handleRoleChange('');
    };

    const handleAddUser = () => {
        addUser({ variables: { name: nameValue, email: emailValue, role: roleValue} })
          .then(result => {
            // Aquí puedes manejar el resultado si es necesario
            handleShowNotification(`El usuario "${nameValue}" fue agregado con éxito.`)
          })
          .catch(error => {
            // Obtener el mensaje de error del objeto error
            const errorMessage = error.message;
            handleOpenModal(errorMessage, 'Oops falló algo!')
          });
      };

      // Función para validar el formulario y habilitar o deshabilitar el botón
      const validateForm = (name, email, role)  => {
        if (name && email && role) {
          setIsButtonEnabled(true);
        } else {
          setIsButtonEnabled(false);
        }
      };

  return (
    <div>
      <Card className="mx-auto w-auto m-10">
        {showNotification && <Notification message={message} />}
          <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Agregar usuario</h3>
          <div className='flex flex-col md:flex-row justify-between mt-5 gap-2'>
            <div className="w-full md:w-2/5">
              <p>Nombre del usuario:</p>
              <TextInput 
                value={nameValue} 
                onValueChange={handleNameChange} 
                placeholder="Ingresa nombre del usuario.." 
              />
            </div>
            <div className="w-full md:w-2/5 mt-4 md:mt-0">
              <p>Correo:</p>
              <TextInput 
                value={emailValue} 
                onValueChange={handleEmailChange} 
                placeholder="Ingresa correo del usuario.."
              />
            </div>
            <div className="w-full md:w-1/5 mt-4 md:mt-0">
              <p>Rol de usuario:</p>
              <Select value={roleValue} defaultValue="1" placeholder='Selecciona..' onValueChange={handleRoleChange}>
                <SelectItem value="master">Master</SelectItem>
                <SelectItem value="admin">Administrador</SelectItem>
              </Select>
            </div>
          </div>
          <div className='flex w-full md:w-auto flex-col justify-center md:justify-end md:flex-row gap-3 mt-7 md:mt-5'>
              <Button 
                  className='bg-slate-500 border-transparent hover:border-transparent hover:bg-slate-700' 
                  icon={RiRefreshLine}
                  onClick={() => handleClearStates()}
                  > Limpiar
              </Button>
              <Button 
                  className='bg-[#38B866] border-transparent hover:border-transparent hover:bg-green-600' 
                  icon={RiAddLine}
                  onClick={() => handleAddUser()}
                  disabled={!isButtonEnabled}
                  >Agregar
              </Button>
          </div>
        </Card>
    </div>
  )
}

export default AgregarUsuario
