import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ListaRegistros from '../pages/ListaRegistros';
import Registro from '../pages/Registro';
import Manifiestos from '../pages/Manifiestos';
import Home from '../pages/Home';
import Tablero from '../pages/Tablero';
import Empresas from '../pages/Empresas';
import Usuarios from '../pages/Usuarios';
import SideBar from '../components/sidebar/SideBar';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import NoPage from '../pages/NoPage';
import logoCIBA from '../assets/cibalogo.png'

const ProtectedComponent = ({ component }) => {
  const Cp = withAuthenticationRequired(component, {
    onRedirecting: () => 
      <div className='w-full h-screen flex flex-col justify-center items-center'>
        <img src={logoCIBA} className='object-contain h-24 w-40 m-5'></img>
        <p>Cargando...</p>
      </div>,
  });
  return <Cp />
}


const RoutesApp = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className='flex'>
      {isAuthenticated && <SideBar/>}
      <Routes>
        <Route path="/" element={<ProtectedComponent component={Home} />} /> {/* aProps={prop1} anotherProp={prop2}  */}
        <Route path="/tablero" element={<ProtectedComponent component={Tablero} />} />
        <Route path="/registro" element={<ProtectedComponent component={Registro} />} />
        <Route path="/manifiestos" element={<ProtectedComponent component={Manifiestos} />} />
        {/* <Route path="/registro/:registro" element={<ProtectedComponent component={Registro} />} /> */}
        <Route path="/usuarios" element={<ProtectedComponent component={Usuarios} />} />
        <Route path="/empresas" element={<ProtectedComponent component={Empresas} />} />
        <Route path="*" element={<ProtectedComponent component={NoPage}/>} />
      </Routes>
    </div>
  )
}

export default RoutesApp
