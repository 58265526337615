import { gql } from '@apollo/client';

export const GET_RECORDS2 = gql`
  query Query($pagination: PaginationContent) {
    listRecords(pagination: $pagination) {
      records {
        _id
        company {
          name
          _id
        }
        type
        scrap
        entry_date
        operator_name
        quantity {
          quantity
          type
        }
        manifiested_quantity {
          quantity
          type
        }
        container
        density
        leaks
        destination
        total
      }
      pagination {
        items
        page
        pages
      }
    }
  }
`;

export const GET_RECORDS = gql`
query Query($pagination: PaginationContent, $startDate: Int, $endDate: Int) {
  listRecords(pagination: $pagination, start_date: $startDate, end_date: $endDate) {
    records {
      _id
      company {
        name
        _id
      }
      type
      scrap
      entry_date
      operator_name
      quantity {
        quantity
        type
      }
      manifiested_quantity {
        quantity
        type
      }
      container
      density
      leaks
      destination
      total
    }
    pagination {
      items
      page
      pages
    }
  }
}
`;
export const  ADD_RECORD = gql`
mutation CreateRecord($company: RecordCompanyContent!, $type: RecordTypes!, $scrap: ScrapTypes!, $entryDate: Int!, $quantity: RecordQuantityContent!, $leaks: Boolean!, $total: Float!, $operatorName: String, $density: String, $container: String, $manifiestedQuantity: RecordQuantityContent) {
    createRecord(company: $company, type: $type, scrap: $scrap, entry_date: $entryDate, quantity: $quantity, leaks: $leaks, total: $total, operator_name: $operatorName, density: $density, container: $container, manifiested_quantity: $manifiestedQuantity) {
      _id
      company {
        _id
      }
      type
      total
      leaks
      entry_date
      scrap
      quantity {
        quantity
        type
      }
      operator_name
      density
      container
      manifiested_quantity {
        quantity
        type
      }
    }
  }
`;

export const  GET_SCRAP_QUANTITIES = gql`
  query GetQuantityCalculations($startDate: Int, $endDate: Int) {
    getQuantityCalculations(start_date: $startDate, end_date: $endDate) {
      byScrap {
        total
        _id
      }
    }
  }
`;

export const  GET_CALCULATIONS_SCRAPS = gql`
  query Query($endDate: Int, $startDate: Int) {
    getCalculations(start_date: $startDate, end_date: $endDate) {
      byScrap {
        _id
        total_entry
        total_exit
        total
      }
    }
  }
`;

export const  GET_CALCULATIONS_COMPANY = gql`
  query Query($endDate: Int, $startDate: Int) {
    getCalculations(end_date: $endDate, start_date: $startDate) {
      byCompany {
        _id
        total_entry
        total_exit
        total
      }
    }
  }
`;

export const  GET_CALCULATIONS_TYPE = gql`
  query Query($endDate: Int, $startDate: Int) {
    getCalculations(end_date: $endDate, start_date: $startDate) {
      byCompany {
        _id
        total_entry
        total_exit
        total
      }
    }
  }
`;

export const  UPDATE_RECORD = gql`
  mutation Mutation($id: ID!, $data: RecordContent) {
    updateRecord(_id: $id, data: $data) {
      _id
      company {
        _id
        name
      }
      type
      scrap
      entry_date
      operator_name
      quantity {
        quantity
        type
      }
      manifiested_quantity {
        quantity
        type
      }
      container
      density
      leaks
      destination
      total
    }
  }
`;

export const DELETE_RECORD = gql`
mutation Mutation($id: ID!) {
  deleteRecord(_id: $id) {
    _id
    company {
      _id
      name
    }
    type
    scrap
    entry_date
    operator_name
    quantity {
      quantity
      type
    }
    manifiested_quantity {
      quantity
      type
    }
    container
    density
    leaks
    destination
    total
  }
}
`;